@import '../../../customize/variables';

.column {
  display: inline-block;
}

.columnElement {
  display: flex;
  flex-direction: column;
  margin: 0 12px;
  font-size: 12px;
  font-family: 'Alegreya Sans SC', sans-serif;
}

.columnElementNumber {
  font-size: 25px;
  font-family: 'Lato';
  font-weight: bold;
}

@media (min-width: $breakpoint-lg) {
  .columnElement {
    margin: 0 20px;
    font-size: 16px;
  }

  .columnElementNumber {
    font-size: 40px;
  }
}
