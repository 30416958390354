@import '../../../../customize/variables';

.container {
  padding-bottom: 20px;
  position: relative;
  font-size: $font-size-standard;
}

.image {
  max-width: 100%;
  max-height: 375px;
  border-radius: 10px;
}

.title {
  font-size: 25px;
  font-weight: normal;
  margin: 10px 0;
}

.arrow {
  display: none;
}

.leftSide {
  padding-right: 10px;
  text-align: right;

  &.dateCircle:after {
    right: -21px;
  }
}

.dateCircle:after {
  background: none repeat scroll 0 0 $primary-color;
  border: 3px solid #ececec;
  border-radius: 50%;
  content: '';
  position: absolute;
  top: 4px;
  width: 16px;
  height: 16px;
  z-index: 20;
}

@media (min-width: $breakpoint-lg) {
  .image {
    max-width: 375px;
  }

  .arrow {
    display: block;
    position: absolute;
    top: 2px;

    &:after {
      display: inline-block;
      color: $primary-color;
      font-size: 20px;
    }
  }

  .leftSide {
    padding-right: 70px;
    text-align: right;

    .arrow {
      right: 25px;

      &:after {
        content: '〱';
      }
    }

    &.dateCircle:after {
      right: -11px;
    }
  }

  .rightSide {
    padding-left: 70px;
    text-align: left;

    .arrow {
      left: 25px;

      &:after {
        content: '〱';
        transform: rotateY(180deg);
      }
    }

    &.dateCircle:after {
      left: -11px;
    }
  }
}
