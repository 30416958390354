@import '../../../customize/variables';

$z-index-image-min: 1;
$z-index-image-max: 100;
$z-index-content: $z-index-image-max + 1;

.imageSlider {
  height: 100vh;
  position: relative;
  z-index: $z-index-image-min;
  background-size: cover;
  background-color: #333333;
  background-position: center;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: $z-index-image-max;
    background: rgba(0, 0, 0, 0.3);
  }

  figure {
    animation-name: imageAnimation;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    background-size: cover;
    background-position: center;
    background-color: #333333;
    opacity: 0;
    z-index: $z-index-image-min;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0;
    padding: 0;
  }
}

@keyframes imageAnimation {
  0% {
    opacity: 0;
    z-index: $z-index-image-max;
  }
  5% {
    opacity: 1;
  }
  95% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    z-index: $z-index-image-min;
  }
}

.sliderContent {
  padding: 0 20px;
  z-index: $z-index-content;

  .preTitle {
    font-family: $font-heading;
    font-weight: normal;
    font-size: 40px;
    margin: 15px 0;
  }

  .title {
    font-family: $font-primary-heading;
    font-weight: normal;
    font-size: 62px;
    margin: 15px 0;

    i {
      transform: translateY(10px);
      font-size: 0.8em;
      color: $heart-color;
    }
  }

  .info {
    font-size: 20px;
    font-family: 'Alegreya Sans SC', sans-serif;
    margin: 15px 0;
  }

  .countdown {
    margin-top: 35px;
  }
}

.scrollDownButton {
  margin-top: 35px;
  color: #fff;
  position: relative;
  font-size: 3rem;
  z-index: $z-index-content;
  top: 3rem;
  cursor: pointer;
  animation-name: fade-down;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
}

@keyframes fade-down {
  0% {
    -webkit-transform: translatey(-20px);
    transform: translatey(-20px);
    opacity: 0.8;
  }
  100% {
    -webkit-transform: translatey(20px);
    transform: translatey(20px);
    opacity: 0;
  }
}

.fadeEnter {
  opacity: 0.01;
  transform: scale(1.1);
}

.fadeEnterActive {
  opacity: 1;
  transform: scale(1);
  transition: all 700ms;
}

.fadeExit {
  opacity: 1;
  transform: scale(1);
}

.fadeExitActive {
  opacity: 0.01;
  transform: scale(1.1);
  transition: all 700ms;
}

@media (min-width: $breakpoint-lg) {
  .sliderContent {
    .preTitle {
      font-size: 60px;
    }

    .title {
      font-size: 90px;
    }

    .info {
      font-size: 24px;
    }
  }
}
