@import '../../../customize/variables';

.modal {
  padding: 0 0 1.2rem;
  margin: 0;
  width: 100%;
  min-height: 100%;
  box-sizing: border-box;
  overflow: hidden;
  text-align: center;
  font-family: $font-standard;
}

.fullHeight {
  height: 100vh;
  padding: 50px 10px 10px;
}

.closeButton {
  cursor: pointer;
  outline: 0;
}

@media (min-width: $breakpoint-md) {
  .modal {
    border-radius: 5px;
    width: auto;
    max-width: 800px;
    min-height: 0;
  }

  .fullHeight {
    padding: 1.2rem;
    padding-right: 50px;
  }
}
