$primary-color: #b47a84;
$heart-color: $primary-color;

$map-marker-color: $primary-color;
$map-marker-border-color: #000000;
$map-marker-hover-color: $map-marker-border-color;
$map-marker-hover-border-color: $map-marker-color;

$button-bg-color: $primary-color;
$button-text-color: #ffffff;
$button-border-color: transparent;
$button-hover-bg-color: #8f505a;
$button-hover-text-color: $button-text-color;
$button-hover-border-color: $button-bg-color;

$font-standard: 'Raleway', sans-serif;
$font-primary-heading: 'allexis', cursive;
$font-heading: $font-primary-heading;
$font-size-standard: 16px;
$font-size-large: 20px;
$font-size-heading: 56px;

$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;
