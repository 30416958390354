@import '../../../customize/variables';

.field {
  width: 100%;
  height: 56px;
  border-radius: 4px;
  position: relative;
  background-color: rgba(255, 255, 255, 0.3);
  transition: 0.3s background-color ease-in-out, 0.3s box-shadow ease-in-out;

  &:hover {
    background-color: rgba(255, 255, 255, 0.45);
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05);
  }

  &.active {
    background-color: #ffffff;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.2);

    input {
      padding: 24px 16px 8px 16px;
    }

    label {
      top: 4px;
      opacity: 1;
      color: $primary-color;
    }
  }

  input {
    width: 100%;
    height: 100%;
    position: relative;
    box-sizing: border-box;
    padding: 0px 16px;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    font-weight: 400;
    font-family: 'Lato';
    line-height: normal;
    background-color: transparent;
    outline: none;
    box-shadow: 0px 4px 20px 0px transparent;
    transition: 0.3s background-color ease-in-out, 0.3s box-shadow ease-in-out,
      0.1s padding ease-in-out;

    &::placeholder {
      color: rgba(255, 255, 255, 0.8);
    }
  }

  label {
    position: absolute;
    top: 24px;
    left: 16px;
    font-size: 12px;
    font-weight: 700;
    font-family: 'Lato';
    line-height: 24px;
    color: #ffffff;
    opacity: 0;
    pointer-events: none;
    transition: 0.1s all ease-in-out;
  }
}
